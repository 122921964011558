// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrlApi: "https://gw-bipower-staging.biplus.com.vn",
  imageUrl: "https://gw-bipower-staging.biplus.com.vn/image",
  api: "https://gw-bipower-staging.biplus.com.vn",
  encryptKey: "4ATf3NjzxselTjNaTUgHQdRXvK7ddeys"
};


export const holidays = {
  holiday_short: [
      '09/02/2022', '03/10/2022', '04/30/2022', '05/01/2022'
  ],
  holiday_long: {
    Tet_holiday: [],
    new_years_day: []
  }
};

export const userByPassCheckin = [
  'huonglx@biplus.com.vn',
  'maidnc@biplus.com.vn',
  'hanq@biplus.com.vn',
  'trinhdnh@biplus.com.vn',
  'nghinlp@biplus.com.vn',
  'trivm@biplus.com.vn'
]

export const companyIp = ['27.72.100.124']

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
