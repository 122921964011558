import {
    Component,
    Input,
    OnInit
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subject} from 'rxjs';
import * as moment from 'moment';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-time-range',
    templateUrl: './time-range.component.html',
    styleUrls: ['./time-range.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: TimeRangeComponent,
            multi: true
        }
    ]
})
export class TimeRangeComponent implements OnInit, ControlValueAccessor {

    @Input() formControl: any
    controlValue = {
        from: new Date(),
        to: new Date()
    }
    value: string;
    @Input() placeholder = '';


    _changeFn = (val) => {
    };
    _touchFn = (val) => {
    };
    disabled: boolean = false;
    unsubscribe$ = new Subject();

    from = new Date();
    to = new Date();

    display = '';

    constructor(private toastrService: ToastrService) {
    }


    writeValue(obj: any): void {
        const current = new Date();
        this.from = obj?.from || current;
        this.to = obj?.to || current;
    }

    registerOnChange(fn: any): void {
        this._changeFn = fn;
        this._changeFn(this.controlValue);
    }

    registerOnTouched(fn: any): void {
        this._touchFn = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    getDisplayText(): void {
        setTimeout(() => {
            this.display = `${moment(this.controlValue.from).format('HH:mm')} - ${moment(this.controlValue.to).format('HH:mm')}`;
        })
    }

    ngOnInit(): void {
        this.getDisplayText();
        this.controlValue = this.formControl.value
    }

    updateValue(from: Date, to: Date): void {
        this.from = from;
        this.to = to;
        this.controlValue = {
            from, to
        };
        this._changeFn(this.controlValue);
        this.getDisplayText();
    }

    ngOnDestroy() {
        this.unsubscribe$.next(true);
        this.unsubscribe$.complete();
    }

    change(event: any) {
        var src = /^(\d{2})(\d{2}) (\d{2})(\d{2})$/
        var dst = '$1:$2 - $3:$4'
        this.display = event.target.value.trim().replace(src, dst)
        let date = this.display.split('-');
        if (date.length != 2) {
            this.display = '';
            return;
        }
        let from = date[0].trim().split(':');
        let to = date[1].trim().split(':');
        if (from.length != 2 || to.length !== 2 || !Number.isInteger(parseInt(from[0])) || !Number.isInteger(parseInt(from[1]))
            || !Number.isInteger(parseInt(to[0])) || !Number.isInteger(parseInt(to[1]))) {
            this.display = '';
            return;
        }

        let fromDate = new Date();
        fromDate.setHours(parseInt(from[0]));
        fromDate.setMinutes(parseInt(from[1]));

        let toDate = new Date();
        toDate.setHours(parseInt(to[0]));
        toDate.setMinutes(parseInt(to[1]));
        this.updateValue(fromDate, toDate);
    }
}
